import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../global.css";

function Account() {
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleUpdate = async () => {
    try {
      const response = await fetch("https://api.checkblogger.com/account/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify({ nickname, password })
      });

      if (response.ok) {
        setMessage("Account updated successfully!");
      } else {
        setMessage("Failed to update account.");
      }
    } catch (error) {
      setMessage("Error updating account.");
    }
  };

  return (
    <div className="account-container">
      <h2>Account Settings</h2>
      {message && <p className="message">{message}</p>}
      <input
        type="text"
        placeholder="New Nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      <input
        type="password"
        placeholder="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleUpdate} className="account-btn">Update</button>
      <button onClick={() => navigate("/dashboard")} className="dashboard-btn">Back to Dashboard</button>
    </div>
  );
}

export default Account;