import React from "react";
import "./global.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Bloggers from "./pages/Bloggers";
import AdminDashboard from "./pages/AdminDashboard";
import AdminBloggers from "./pages/AdminBloggers";
import EmailVerification from './pages/EmailVerification';
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* ✅ Защищённые маршруты для пользователей */}
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/account" element={<PrivateRoute element={<Account />} />} />
        <Route path="/bloggers" element={<PrivateRoute element={<Bloggers />} />} />

        {/* ✅ Публичные маршруты */}
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/register" element={<Register />} />

        {/* ✅ Защищённые маршруты для админов */}
        <Route path="/admin/dashboard" element={<PrivateRoute element={<AdminDashboard />} adminOnly={true} />} />
        <Route path="/admin/bloggers" element={<PrivateRoute element={<AdminBloggers />} adminOnly={true} />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
