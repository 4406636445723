import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../global.css";
import "./AdminBloggers.css"; // ✅ Подключаем стили

const AdminBloggers = () => {
  const [bloggers, setBloggers] = useState([]);
  const [editBlogger, setEditBlogger] = useState(null);
  const [updatedData, setUpdatedData] = useState({ 
    name: "", 
    subscribers: "" });
  const [newBlogger, setNewBlogger] = useState({
    name: "",
    link: "",
    subscribers: 0,
    topic: "",
    rating: null,
    city: "",
  });const navigate = useNavigate();

  const fetchBloggers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get("https://api.checkblogger.com/admin/bloggers/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBloggers(response.data);
    } catch (err) {
      console.error("Failed to fetch bloggers:", err);
    }
  };

  useEffect(() => {
    fetchBloggers();
  }, []);

  // ✅ Функция выхода из аккаунта
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/admin");
  };

  // ✅ Функция для удаления блогера
  const deleteBlogger = async (bloggerId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(`https://api.checkblogger.com/admin/bloggers/${bloggerId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchBloggers(); // ✅ Обновляем список после удаления
    } catch (err) {
      console.error("Failed to delete blogger:", err);
    }
  };

  // ✅ Функция для начала редактирования блогера
  const startEditing = (blogger) => {
    setEditBlogger(blogger.id);
    setUpdatedData({ name: blogger.name, subscribers: blogger.subscribers });
  };

  // ✅ Функция для сохранения изменений
  const saveChanges = async (bloggerId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `https://api.checkblogger.com/admin/bloggers/${bloggerId}/`,
        updatedData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchBloggers(); // ✅ Перезапрашиваем данные с сервера после обновления
      setEditBlogger(null);
    } catch (err) {
      console.error("Failed to update blogger:", err);
    }
  };

  const addBlogger = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const { name, link, subscribers, topic, rating, city } = newBlogger;
      
      await axios.post(
        "https://api.checkblogger.com/admin/bloggers/",
        { name, link, subscribers, topic, rating, city },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setNewBlogger({ name: "", link: "", subscribers: 0, topic: "", rating: null, city: "" });
      fetchBloggers();
    } catch (err) {
      console.error("Failed to add blogger:", err);
    }
  };
  
  return (
    <div className="admin-container">
      <h2>Admin Bloggers</h2>

      {/* ✅ Кнопки навигации */}
      <div className="nav-buttons">
        <button onClick={() => navigate("/admin/dashboard")} className="nav-btn">Go to Dashboard</button>
        <button onClick={handleLogout} className="logout-btn">Logout</button>
      </div>

      <h3>Bloggers List</h3>

      {/* ✅ Форма для добавления нового блогера */}
      <div className="add-blogger-form">
        <input
          type="text"
          placeholder="Name"
          value={newBlogger.name}
          onChange={(e) => setNewBlogger({ ...newBlogger, name: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Link"
          value={newBlogger.link}
          onChange={(e) => setNewBlogger({ ...newBlogger, link: e.target.value })}
          required
        />
        <input
          type="number"
          placeholder="Subscribers"
          value={newBlogger.subscribers}
          onChange={(e) => setNewBlogger({ ...newBlogger, subscribers: Number(e.target.value) })}
        />

        <input
          type="text"
          placeholder="Topic (optional)"
          value={newBlogger.topic || ""}
          onChange={(e) => setNewBlogger({ ...newBlogger, topic: e.target.value })}
        />
        <input
          type="number"
          step="0.1"
          placeholder="Rating (optional)"
          value={newBlogger.rating || ""}
          onChange={(e) => setNewBlogger({ ...newBlogger, rating: parseFloat(e.target.value) || null })}
        />

        <input
          type="text"
          placeholder="City (optional)"
          value={newBlogger.city || ""}
          onChange={(e) => setNewBlogger({ ...newBlogger, city: e.target.value })}
        />

        <button className="admin-btn add" onClick={addBlogger}>Add Blogger</button>
      </div>



      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Link</th>
            <th>Social Network</th>
            <th>Subscribers</th>
            <th>Topic</th>
            <th>Rating</th>
            <th>City</th>
            <th>Added At</th>
            <th>User ID</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bloggers.map((blogger) => (
            <tr key={blogger.id}>
              <td>{blogger.id}</td>
              <td>
                {editBlogger === blogger.id ? (
                  <input 
                    type="text"
                    value={updatedData.name}
                    onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                  />
                ) : (
                  blogger.name
                )}
              </td>
              <td>
                <a href={blogger.link} target="_blank" rel="noopener noreferrer">{blogger.link}</a>
              </td>
              <td>{blogger.social_network}</td>
              <td>
                {editBlogger === blogger.id ? (
                  <input 
                    type="number"
                    value={updatedData.subscribers}
                    onChange={(e) => setUpdatedData({ ...updatedData, subscribers: e.target.value })}
                  />
                ) : (
                  blogger.subscribers
                )}
              </td>
              <td>{blogger.topic || "—"}</td>
              <td>{blogger.rating !== null ? blogger.rating : "—"}</td>
              <td>{blogger.city || "—"}</td>
              <td>
                {blogger.added_at ? new Date(blogger.added_at).toLocaleString() : "No date"}
              </td>
              <td>{blogger.user_id}</td>
              <td>
                {blogger.updated_at ? new Date(blogger.updated_at).toLocaleString() : "Not updated"}
              </td>
              <td>
                {editBlogger === blogger.id ? (
                  <button className="admin-btn save" onClick={() => saveChanges(blogger.id)}>Save</button>
                ) : (
                  <button className="admin-btn edit" onClick={() => startEditing(blogger)}>Edit</button>
                )}
                <button className="admin-btn delete" onClick={() => deleteBlogger(blogger.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminBloggers;