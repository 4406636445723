import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../global.css";

const Bloggers = () => {
  const [bloggers, setBloggers] = useState([]);
  const [editBlogger, setEditBlogger] = useState(null);
  const [updatedData, setUpdatedData] = useState({ name: "", subscribers: "" });
  const [newBlogger, setNewBlogger] = useState({ name: "", link: "", social_network: "", subscribers: 0 });
  const navigate = useNavigate();

  // ✅ Загружаем блогеров пользователя
  const fetchBloggers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get("https://api.checkblogger.com/bloggers/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBloggers(response.data);
    } catch (err) {
      console.error("Failed to fetch bloggers:", err);
    }
  };

  useEffect(() => {
    fetchBloggers();
  }, []);

  // ✅ Функция удаления блогера из списка пользователя
  const deleteBlogger = async (bloggerId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(`https://api.checkblogger.com/bloggers/${bloggerId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchBloggers(); // ✅ Обновляем список после удаления
    } catch (err) {
      console.error("Failed to remove blogger from user list:", err);
    }
  };

  // ✅ Функция для начала редактирования
  const startEditing = (blogger) => {
    setEditBlogger(blogger.id);
    setUpdatedData({ name: blogger.name, subscribers: blogger.subscribers });
  };

  // ✅ Функция для сохранения изменений
  const saveChanges = async (bloggerId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `https://api.checkblogger.com/bloggers/${bloggerId}/`,
        updatedData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchBloggers(); // ✅ Перезапрашиваем данные
      setEditBlogger(null);
    } catch (err) {
      console.error("Failed to update blogger:", err);
    }
  };

  // ✅ Функция для добавления нового блогера
  const addBlogger = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.post(
        "https://api.checkblogger.com/bloggers/",
        newBlogger,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNewBlogger({ name: "", link: "", social_network: "", subscribers: 0 }); // ✅ Очищаем форму
      fetchBloggers(); // ✅ Загружаем обновлённые данные
    } catch (err) {
      console.error("Failed to add blogger:", err);
    }
  };

  return (
    <div className="bloggers-container">
      <h2>My Bloggers</h2>

      {/* ✅ Кнопка назад */}
      <button onClick={() => navigate("/dashboard")} className="dashboard-btn">Back to Dashboard</button>

      {/* ✅ Форма для добавления блогера */}
      <div className="add-blogger-form">
        <input
          type="text"
          placeholder="Name"
          value={newBlogger.name}
          onChange={(e) => setNewBlogger({ ...newBlogger, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Link"
          value={newBlogger.link}
          onChange={(e) => setNewBlogger({ ...newBlogger, link: e.target.value })}
        />
        <input
          type="text"
          placeholder="Social Network"
          value={newBlogger.social_network}
          onChange={(e) => setNewBlogger({ ...newBlogger, social_network: e.target.value })}
        />
        <input
          type="number"
          placeholder="Subscribers"
          value={newBlogger.subscribers}
          onChange={(e) => setNewBlogger({ ...newBlogger, subscribers: e.target.value })}
        />
        <button className="dashboard-btn" onClick={addBlogger}>Add Blogger</button>
      </div>

      {/* ✅ Таблица блогеров */}
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Link</th>
            <th>Social Network</th>
            <th>Subscribers</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bloggers.map((blogger) => (
            <tr key={blogger.id}>
              <td>
                {editBlogger === blogger.id ? (
                  <input 
                    type="text"
                    value={updatedData.name}
                    onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                  />
                ) : (
                  blogger.name
                )}
              </td>
              <td><a href={blogger.link} target="_blank" rel="noopener noreferrer">{blogger.link}</a></td>
              <td>{blogger.social_network}</td>
              <td>
                {editBlogger === blogger.id ? (
                  <input 
                    type="number"
                    value={updatedData.subscribers}
                    onChange={(e) => setUpdatedData({ ...updatedData, subscribers: e.target.value })}
                  />
                ) : (
                  blogger.subscribers
                )}
              </td>
              <td>
                {editBlogger === blogger.id ? (
                  <button className="dashboard-btn" onClick={() => saveChanges(blogger.id)}>Save</button>
                ) : (
                  <button className="dashboard-btn" onClick={() => startEditing(blogger)}>Edit</button>
                )}
                <button className="dashboard-btn logout" onClick={() => deleteBlogger(blogger.id)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bloggers;