import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const NotFound = () => {
  const [isAuthenticated] = useState(!!localStorage.getItem("access_token"));
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("is_admin") === "true");

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return isAdmin ? (
    <Navigate to="/admin/dashboard" replace />
  ) : (
    <Navigate to="/dashboard" replace />
  );
};

export default NotFound;
