import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../global.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(
        "https://api.checkblogger.com/token",
        new URLSearchParams({ username, password, grant_type: "password" }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      const token = response.data.access_token;
      localStorage.setItem("access_token", token);

      // Проверим админ ли пользователь
      const userResponse = await axios.get("https://api.checkblogger.com/protected/", {
        headers: { Authorization: `Bearer ${token}` },
      });

      const userIsAdmin = userResponse.data.is_admin;
      localStorage.setItem("is_admin", userIsAdmin ? "true" : "false");

      if (userIsAdmin) {
        navigate("/admin/dashboard");
      } else {
        navigate("/dashboard");
      }

    } catch (err) {
      console.error(err.response ? err.response.data : err);
      setError(err.response?.data?.detail || "An error occurred during login.");
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;
