import React, { useState } from "react";
import axios from "axios";
import "../global.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
  
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
  
    if (password.length < 8 || !/[A-Z]/.test(password)) {
      setError("Password must be at least 8 characters and include at least one uppercase letter.");
      return;
    }
  
    try {
      await axios.post("https://api.checkblogger.com/register/", {
        email,
        password,
      });
  
      setSuccess("Registration successful! Check your email.");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      setError(err.response?.data?.detail || "Registration error!");
    }
  };
  
  return (
    <div className="register-container">
      <h2>Register</h2>

      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}

      <form onSubmit={handleSubmit} className="register-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
  );
}

export default Register;
