import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../global.css";
import "./AdminDashboard.css"; // ✅ Подключаем стили

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get("https://api.checkblogger.com/admin/users/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (err) {
        console.error("Access denied or session expired.");
        navigate("/admin");
      }
    };
    fetchUsers();
  }, [navigate]);

  const deleteUser = async (userId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(`https://api.checkblogger.com/admin/users/${userId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUsers(users.filter(user => user.id !== userId));
    } catch (err) {
      console.error("Failed to delete user:", err);
    }
  };

  const makeAdmin = async (userId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(`https://api.checkblogger.com/admin/users/${userId}/`, 
        { is_admin: true }, 
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_admin: true } : user
      ));
    } catch (err) {
      console.error("Failed to update user:", err);
    }
  };

  // ✅ Функция выхода из аккаунта
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("is_admin"); // ✅ Очищаем статус админа
    navigate("/");
  };
  

  return (
    <div className="admin-container">
      <h2>Admin Dashboard</h2>

      {/* ✅ Кнопки навигации */}
      <div className="nav-buttons">
        <button onClick={() => navigate("/admin/bloggers")} className="nav-btn">Go to Bloggers</button>
        <button onClick={handleLogout} className="logout-btn">Logout</button>
      </div>

      <h3>Users List</h3>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.is_admin ? "Admin" : "User"}</td>
              <td>
                {!user.is_admin && (
                  <button className="admin-btn make-admin" onClick={() => makeAdmin(user.id)}>Make Admin</button>
                )}
                <button className="admin-btn delete" onClick={() => deleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;