import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const status = searchParams.get("status");
    const token = searchParams.get("token");

    if (status === "success" && token) {
      localStorage.setItem("access_token", token);
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [searchParams, navigate]);

  return (
    <div className="email-verification-container">
      <p>Verifying your email...</p>
    </div>
  );
};

export default EmailVerification;