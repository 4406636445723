import React from "react";
import { useNavigate } from "react-router-dom";
import "../global.css";

function Dashboard() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("is_admin"); // ✅ Очищаем статус админа
    navigate("/");
  };
  

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <div className="dashboard-buttons">
        <button onClick={() => navigate("/account")} className="dashboard-btn">Account Settings</button>
        <button onClick={() => navigate("/bloggers")} className="dashboard-btn">My Bloggers</button>
        <button onClick={handleLogout} className="dashboard-btn logout">Logout</button>
      </div>
    </div>
  );
}

export default Dashboard;